/**
 * @Author: hongweihu
 * @Date: 2019-12-04 16:37:23.969
 * @Last Modified by: hongweihu
 * @Last Modified time: 2024-07-05 14:23:25
 */
import AboutUs from './component/about-us';
import Banner from './component/banner';
import Business from './component/business';
import Level from './component/level';
import YqgFooter from './component/yqg-footer';
import YqgHeader from './component/yqg-header';
import YqgVideo from './component/yqg-video';

export default {
    name: 'Index',

    title: '黑龙江三农信融资担保有限公司',

    head() {
        const content = '黑龙江三农信融资担保有限公司是经批准授权的融资性担保机构，为中小微企业提供稳定优质的担保服务。';

        return {meta: [{name: 'description', content}]};
    },

    components: {
        YqgHeader,
        Banner,
        AboutUs,
        Level,
        Business,
        YqgFooter,
        YqgVideo
    }
};
