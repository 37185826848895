var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "business" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("\n            业务介绍\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "business-list" }, [
          _c("div", { staticClass: "business-info" }, [
            _c("img", { attrs: { src: require("./img/icon-01.png") } }),
            _vm._v(" "),
            _c("span", { staticClass: "business-title" }, [
              _vm._v("融资类担保业务"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "business-desc" }, [
              _vm._v("借款类担保"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "business-desc" }, [
              _vm._v("发行债券担保"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "business-desc" }, [
              _vm._v("其他融资担保"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "business-info second" }, [
            _c("img", { attrs: { src: require("./img/icon-02.png") } }),
            _vm._v(" "),
            _c("span", { staticClass: "business-title" }, [
              _vm._v("非融资类担保业务"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col col-1" }, [
                _c("span", { staticClass: "business-desc" }, [
                  _vm._v("投标担保"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "business-desc" }, [
                  _vm._v("工程履约担保"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col col-2" }, [
                _c("span", { staticClass: "business-desc" }, [
                  _vm._v("诉讼保全担保"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "business-desc" }, [
                  _vm._v("其他非融资担保"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "business-info" }, [
            _c("img", { attrs: { src: require("./img/icon-03.png") } }),
            _vm._v(" "),
            _c("span", { staticClass: "business-title" }, [_vm._v("其他业务")]),
            _vm._v(" "),
            _c("span", { staticClass: "business-desc" }, [
              _vm._v("与担保业务有关的咨询"),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "business-desc" }, [
              _vm._v("其他合法合规业务"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }