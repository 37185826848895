var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "about-us" }, [
      _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "title" }, [_vm._v("关于我们")]),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c("p", { staticClass: "first-content" }, [
            _vm._v(
              "\n                黑龙江三农信融资担保有限公司（简称“三农信”）成立于2017年2月，注册资本金15亿元。2021年评为资信等级AAA企业。\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n                公司成立至今，以增强企业信用、降低企业经营风险为己任，凭借良好的经营业绩和较强的资金实力，树立了良好的社会形象；\n                积极响应政策号召，为中小微企业提供多元化的产品服务，根据企业实际情况为其推荐不同的融资担保服务，\n                大力支持中小微企业转型升级，公司也因此赢得了一大批优质的长期稳定合作客户。\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n            未来， 三农信仍将继续弘扬科学管理、创新发展的经营理念，不断推动全社会诚信体系的完善，\n            践行社会责任，为中小企业的健康发展和国家建设作出应有的贡献！\n        "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }