var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-footer" }, [
    _c("div", { staticClass: "container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "copyright-info" }, [
        _c("div", { staticClass: "company-info" }, [
          _vm._v(
            "\n                ©2017 黑龙江三农信融资担保有限公司\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "copyright",
            on: {
              click: function ($event) {
                return _vm.jumpTo("https://beian.miit.gov.cn/")
              },
            },
          },
          [_vm._v("\n                黑ICP备2020005445号\n            ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-info" }, [
      _c("span", { staticClass: "tel" }, [
        _vm._v("\n                客服热线：4000012715\n            "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "work-time" }, [
        _vm._v("\n                客服工作时间：8:00~21:00\n            "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }