<!-- @Author: hongweihu -->
<!-- @Date: 2024-07-05 10:54:32.384 -->
<!-- @Last Modified by: hongweihu -->
<!-- @Last Modified time: 2024-07-05 14:23:24 -->

<template>
    <div class="yqg-video">
        <div class="container">
            <div class="video-title">
                中国人民银行征信中心创意宣传片展示
            </div>
            <video
                id="video"
                src="https://public.yangqianguan.com/upload/yqg-mayuri-web/ll6Vgh2uDbLtyxwByGCP9WWV6Bsg.m4v"
                controls
                preload
                controlslist="nodownload"
                class="video"
                poster="https://public.yangqianguan.com/upload/yqg-mayuri-web/Fnsn51Jiy81x2qEfu5gE295bt9tk.png"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgVideo'
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $ultra-book-min: 760px;

    .yqg-video {
        padding: 120px 0;
        box-sizing: border-box;

        .video-title {
            font-size: 36px;
            color: #333;
            margin-bottom: 60px;
        }

        .video {
            width: 100%;
        }

        @media screen and (max-width: $ultra-book-min) {
            padding: 40px 0;

            .container {
                .video-title {
                    font-size: 24px;
                    color: #333;
                    margin-bottom: 40px;
                }
            }
        }
    }

</style>
